import awsmobile from "../aws-exports"
import {
    ADAPTIVE_PROGRAM_ID,
    CLASS_PROGRAM_ID,
    DEMANTUR_PROGRAM_ID,
    GOLDEN_DIAMOND_ID,
    HYROX,
    MASTER_PROGRAM_ID,
    SPECIAL_COMPETITION_PROGRAM_ATHENS_ID,
    SPECIAL_COMPETITION_PROGRAM_GALLIPOLI_ID,
    SPECIAL_COMPETITION_PROGRAM_ITALIAN_BULLS_ID,
    SPECIAL_COMPETITION_PROGRAM_ITALIAN_SHOWDOWN_ID,
    SPECIAL_COMPETITION_PROGRAM_UROBORO_ID,
    WORKOUT_GROUP_ADAPTIVE,
    WORKOUT_GROUP_CLASS,
    WORKOUT_GROUP_DEMANTUR,
    WORKOUT_GROUP_GOLDEN,
    WORKOUT_GROUP_HYROX,
    WORKOUT_GROUP_MASTER,
    WORKOUT_GROUP_SCP_ATHENS_2024,
    WORKOUT_GROUP_SCP_GALLIPOLI_2024,
    WORKOUT_GROUP_SCP_ITALIANBULLS_2024,
    WORKOUT_GROUP_SCP_ITALIANSHOWDOWN,
    WORKOUT_GROUP_SCP_UROBORO_2024
} from "../v2/resources/data/plans"

//fields types
export const fieldsTypeStandard = "standard"
export const fieldsTypeRM = "rm"
//content
export const workoutContentTypeList = "list"
//workoutStatus
export const workoutLoading = "loading"
export const workoutNotFound = "not_found"
export const workoutFound = "found"
//workout types
export const wTypeAdvanced = "advanced"
export const wTypeMaster = "master"
export const wTypeElite = "elite"
export const wTypeCompetitor = "competitor"
export const wTypeDemantur = "demantur"
export const wTypeTemporaryContest = "contest"
export const wTypeBox = "box"
export const wTypeGolden = "golden"
//golden programs
export const goldenTier1 = "goldenTier1"
export const goldenTier2 = "goldenTier2"
export const goldenTier3 = "goldenTier3"

//programsGroupConstants
export const groupElite = "ELITE"
export const groupCompetitor = "COMPETITOR-2022"
export const groupAdvanced = "ADVANCED-2022"
export const groupMaster = "MASTER-2022"
export const groupClass = "CLASS-2022"
export const groupGenericGolden = "GOLDEN_DIAMOND"
export const groupBox = "BOX_USERS"
export const groupBoxOwners = "BOX_OWNERS"
export const groupAdmin = "ADMIN"
export const groupAdminUser = "ADMIN_USER"
export const groupEditorClass = "EDITOR_CLASS"
export const groupEditorHyrox = "EDITOR_HYROX"
export const groupEditorMaster = "EDITOR_MASTER"
export const groupEditorAdaptive = "EDITOR_ADAPTIVE"
export const groupEditorSpecialComp = "EDITOR_SPECIAL_COMP"
export const groupEditorDemantur = "EDITOR_DEMANTUR"
export const groupEditorGolden = "EDITOR_GOLDEN"
export const groupPkgStrength = "PACKAGE_STRENGTH_2022"
export const groupPkgEndurance = "PACKAGE_ENDURANCE_2022"
export const groupGoldenTier1 = "GOLDEN_DIAMOND_BASIC"
export const groupGoldenTier2 = "GOLDEN_DIAMOND_PRO"
export const groupGoldenTier3 = "GOLDEN_DIAMOND_VIP"
export const groupTempContest = process.env.REACT_APP_PAYPAL_PLAN_TMP_GROUP
//groupCanAccess
export const groupAccessMatrix = {
    [wTypeCompetitor]: groupCompetitor,
    [wTypeAdvanced]: groupAdvanced,
    [wTypeMaster]: groupMaster,
    [wTypeElite]: groupElite,
    [wTypeBox]: groupClass,
    [wTypeTemporaryContest]: groupTempContest,
    [wTypeGolden]: groupGenericGolden,
    [goldenTier1]: groupGoldenTier1,
    [goldenTier2]: groupGoldenTier2,
    [goldenTier3]: groupGoldenTier3,
    [GOLDEN_DIAMOND_ID]: WORKOUT_GROUP_GOLDEN,
    [DEMANTUR_PROGRAM_ID]: WORKOUT_GROUP_DEMANTUR,
    [HYROX]: WORKOUT_GROUP_HYROX,
    [ADAPTIVE_PROGRAM_ID]: WORKOUT_GROUP_ADAPTIVE,
    [CLASS_PROGRAM_ID]: WORKOUT_GROUP_CLASS,
    [MASTER_PROGRAM_ID]: WORKOUT_GROUP_MASTER,
    [SPECIAL_COMPETITION_PROGRAM_ATHENS_ID]: WORKOUT_GROUP_SCP_ATHENS_2024,
    [SPECIAL_COMPETITION_PROGRAM_ITALIAN_BULLS_ID]: WORKOUT_GROUP_SCP_ITALIANBULLS_2024,
    [SPECIAL_COMPETITION_PROGRAM_ITALIAN_SHOWDOWN_ID]: WORKOUT_GROUP_SCP_ITALIANSHOWDOWN,
    [SPECIAL_COMPETITION_PROGRAM_UROBORO_ID]: WORKOUT_GROUP_SCP_UROBORO_2024,
    [SPECIAL_COMPETITION_PROGRAM_GALLIPOLI_ID]: WORKOUT_GROUP_SCP_GALLIPOLI_2024
}
export const editorGroups = {
    [WORKOUT_GROUP_GOLDEN]: groupEditorGolden,
    [WORKOUT_GROUP_DEMANTUR]: groupEditorDemantur,
    [WORKOUT_GROUP_HYROX]: groupEditorHyrox,
    [WORKOUT_GROUP_ADAPTIVE]: groupEditorAdaptive,
    [WORKOUT_GROUP_CLASS]: groupEditorClass,
    [WORKOUT_GROUP_MASTER]: groupEditorMaster
}
export const paymentTypeOneTime = "onetime"
export const paymentTypeRecurring = "recurring"

export const homepageTypeItaly = "italy"
export const homepageTypeWorld = "world"
//score types
export const scoreTypeNoScore = "none"
//score decorations
export const scoreDecorationTimer = "timer"
export const scoreDecorationCrono = "crono"
export const scoreDecorationTabata = "tabata"
//pkg fields
export const packageTypeTransient = "pkgType"
export const lastVisitedWorkout = "lastVisitedWorkout"
//cachePrefix
export const cachePrefix = "dpcache_04_"
//querylimits
export const noticeLimits = 10
//auth
export const routeAuthenticated = "authenticated"
//paypal
export const paypalClientId =
    "AcYmpUh9iAhzbyyfsEs4fU-ki5B6fYPQxDKfiuF0fFtceC3Oh-5RPpsUn8BJa-Ymxqwv4_OhaBx61KYX"
export const paypalStagingClientId =
    "AS29zYs7r12Kq5eNlUxCCJGfuxKa6lyBwrAv35LIs4KXDrvvltF6BRNcj0WdzuiiKGS9uIMLZ6fs3VPY"
//paypal plans
export const planIdCompetitor = process.env.REACT_APP_PAYPAL_PLAN_COMPETITOR
export const planIdCompetitorTrial = process.env.REACT_APP_PAYPAL_PLAN_COMPETITOR_TRIAL
// export const planIdCompetitor = "P-6RP00166DW1155406MI4LX5A";
export const planIdAdvanced = process.env.REACT_APP_PAYPAL_PLAN_ADVANCE
export const planIdAdvancedTrial = process.env.REACT_APP_PAYPAL_PLAN_ADVANCE_TRIAL
export const planIdContestTemp = process.env.REACT_APP_PAYPAL_PLAN_TMP_CONTEST
export const planIdMaster = process.env.REACT_APP_PAYPAL_PLAN_MASTER
export const planIdMasterTrial = process.env.REACT_APP_PAYPAL_PLAN_MASTER_TRIAL
export const planIdElite = process.env.REACT_APP_PAYPAL_PLAN_ELITE
export const planIdEliteTrial = process.env.REACT_APP_PAYPAL_PLAN_ELITE_TRIAL
//golden
export const planIdGoldenTier1 = process.env.REACT_APP_PAYPAL_PLAN_GOLDEN_BASIC
export const planIdGoldenTier2 = process.env.REACT_APP_PAYPAL_PLAN_GOLDEN_PRO
export const planIdGoldenTier3 = process.env.REACT_APP_PAYPAL_PLAN_GOLDEN_VIP
export const planIdGoldenTier1Trial = process.env.REACT_APP_PAYPAL_PLAN_GOLDEN_BASIC_TRIAL

export const planIdGoldenTier2Trial = process.env.REACT_APP_PAYPAL_PLAN_GOLDEN_PRO_TRIAL
export const planIdGoldenTier3Trial = process.env.REACT_APP_PAYPAL_PLAN_GOLDEN_VIP_TRIAL
//sessionStorageKeys
export const cognitoUserId = "cognitoUserId"
//editor
export const scoreDefaultCaption = "[ADD CAPTION]"
export const extraWorkIdentifier = "Accessory"
//images
export const maxImageSize = 5242880
const baseImagesResUri = "https://storage.demanturprogram.com/assets/resources/images/"
const baseSoundResUri = "https://storage.demanturprogram.com/assets/resources/sounds/"
const baseProgramsUri = baseImagesResUri + "homepage/programs/"
const baseOptimizedUri = baseImagesResUri + "optimized/"
const baseOptimizedProgramsUri = baseOptimizedUri + "programs/"
export const LogoProgram = baseImagesResUri + "logo.svg"
export const Diamond = baseImagesResUri + "diamond.png"
export const DividerImg = baseImagesResUri + "divider.png"
//golden
export const GoldenLogo = baseImagesResUri + "goldenDiamondLogo.svg"
export const GoldenTier1Img = baseProgramsUri + "golden/GD-Basic.png"
export const GoldenTier2Img = baseProgramsUri + "golden/GD-Pro.png"
export const GoldenTier3Img = baseProgramsUri + "golden/GD-Vip.png"

export const CoachTeo = baseImagesResUri + "team/Teo.webp"
export const CoachFra = baseImagesResUri + "team/Ragone.webp"
export const CoachLeo = baseImagesResUri + "team/Leo.webp"
export const CoachMirna = baseImagesResUri + "team/Mirna.webp"
export const FrontPage = baseImagesResUri + "frontpage2023.webp"
//sounds
export const TabataRoundEnd = baseSoundResUri + "tabata_round_end.mp3"
export const TimerEnd = baseSoundResUri + "timer_end.mp3"
export const TimerPrepare = baseSoundResUri + "timer_prepare.mp3"

//optimizedImages
export const IMG_pkg_endurance = baseOptimizedUri + "pkg_endurance.webp"
export const IMG_pkg_strength = baseOptimizedUri + "pkg_strength.webp"
export const IMG_tmp_romethrowdown = baseOptimizedUri + "tmp_romethrowdown.webp"

//optimizedProgramsImages
export const IMG_prg_competitor = baseOptimizedProgramsUri + "Competitors.webp"
export const IMG_prg_advanced = baseOptimizedProgramsUri + "Advanced.webp"
export const IMG_prg_elite = baseOptimizedProgramsUri + "Elite.webp"
export const IMG_prg_master = baseOptimizedProgramsUri + "Master.webp"
export const IMG_prg_box = baseOptimizedProgramsUri + "Box.webp"
//graph
export const benchCat_STRENGTH = "STRENGTH"
export const benchCat_WEIGHTLIFTING = "WEIGHTLIFTING"
export const benchCat_GYMNASTIC = "GYMNASTIC"
export const benchCat_WORKCAPACITY = "WORK_CAPACITY"
export const benchCat_POWER = "POWER"
export const benchCat_ENDURANCE = "ENDURANCE"
//otherImages
export const IMG_default_box = baseOptimizedUri + "Sito_Immagine_box.png"

export const s3BaseUri =
    "https://" +
    awsmobile.aws_user_files_s3_bucket +
    ".s3." +
    awsmobile.aws_user_files_s3_bucket_region +
    ".amazonaws.com/"
export const s3PublicUri = s3BaseUri + "public/"
export const deleteSubLinkStaging =
    "https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_subscr-find&alias="
export const deleteSubLinkProd = "https://www.paypal.com/cgi-bin/webscr?cmd=_subscr-find&alias="
