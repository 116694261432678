import { createSlice } from "@reduxjs/toolkit"

export const cognitoUserInfo = createSlice({
    name: "cognitoUserInfo",
    initialState: {
        cognitoUser: undefined,
        cognitoUserGroup: undefined
    },
    reducers: {
        set: (state, action) => {
            state.cognitoUser = action.payload
        },
        setGroup: (state, action) => {
            state.cognitoUserGroup = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { set, setGroup } = cognitoUserInfo.actions

export default cognitoUserInfo.reducer
