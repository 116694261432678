/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://jbxoqa2x3zbhzhnafgwpboureq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-msqgu6xj4raxbhb4h6jqtbcnly",
    "aws_cognito_identity_pool_id": "eu-central-1:8f23ddf1-ca2c-406c-8a94-9f8d1dd6bb43",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_fSdUotD9u",
    "aws_user_pools_web_client_id": "3q18401noev6fqj6k2vrumdh90",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "demanturreact-storage-d3sc2c9cj1v78w151339-newstaging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
