import { configureStore } from "@reduxjs/toolkit"
import userInfoReducer from "./slices/userInfo"
import cognitoUserInfoReducer from "./slices/cognitoUserInfo"
import globalAppReducer from "./slices/globalApp"
import adminDataReducer from "./slices/adminData"
import graphqlContentReducer from "./slices/graphqlContent"

export default configureStore({
    reducer: {
        userInfo: userInfoReducer,
        graphqlContent: graphqlContentReducer,
        cognitoUserInfo: cognitoUserInfoReducer,
        globalApp: globalAppReducer,
        adminData: adminDataReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})
