const colorDemantur = { value: "#46f92a" }
export const theme = {
    name: "my-theme",
    tokens: {
        colors: {
            background: {
                primary: { value: "black" },
                secondary: { value: "#2b2b2b" }
            },
            font: {
                primary: { value: "#a2a2a2" },
                secondary: { value: "#a2a2a2" },
                interactive: colorDemantur
            }
        },
        components: {
            tabs: {
                item: {
                    active: {
                        color: colorDemantur
                    },
                    focus: {
                        color: colorDemantur
                    }
                }
            },
            button: {
                primary: {
                    border: { value: 5 },
                    borderColor: colorDemantur,
                    backgroundColor: { value: "black" },
                    color: colorDemantur,
                    hover: {
                        border: { value: 5 },
                        borderColor: { value: "white" },
                        backgroundColor: { value: "black" },
                        color: { value: "white" }
                    },
                    disabled: {
                        border: { value: 5 },
                        borderColor: { value: "gray" },
                        backgroundColor: { value: "black" },
                        color: { value: "gray" }
                    }
                }
            }
        }
    }
}
