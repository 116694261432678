import { createSlice } from "@reduxjs/toolkit"

export const graphqlContent = createSlice({
    name: "graphqlContent",
    initialState: {
        news: [],
        team: [],
        box: []
    },
    reducers: {
        loadNews: (state, action) => {
            state.news = action.payload
        },
        loadTeam: (state, action) => {
            state.team = action.payload
        },
        loadBox: (state, action) => {
            state.box = action.payload
        }
    }
})

export const { loadNews, loadTeam, loadBox } = graphqlContent.actions

export default graphqlContent.reducer
