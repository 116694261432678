export const GOLDEN_DIAMOND_ID = "golden-diamond"
export const DEMANTUR_PROGRAM_ID = "demantur-program"
export const CLASS_PROGRAM_ID = "class"
export const ADAPTIVE_PROGRAM_ID = "adaptive"
export const HYROX = "hyrox"
export const MASTER_PROGRAM_ID = "master"
export const GROUP_PROGRAM_ID = "group"
export const ENDURANCE_PROGRAM_ID = "endurance"
export const STRENGTH_PROGRAM_ID = "strength"
export const SPECIFIC_PROGRAM_ID = "specific-programs"
export const SPECIAL_COMPETITION_PROGRAM_ID = "special-competition"
export const SPECIAL_COMPETITION_PROGRAM_ATHENS_ID = "special-competition-athens"
export const SPECIAL_COMPETITION_PROGRAM_ITALIAN_BULLS_ID = "special-competition-italianbulls"
export const SPECIAL_COMPETITION_PROGRAM_ITALIAN_SHOWDOWN_ID = "special-competition-italianshowdown"
export const SPECIAL_COMPETITION_PROGRAM_UROBORO_ID = "special-competition-uroboro"
export const SPECIAL_COMPETITION_PROGRAM_GALLIPOLI_ID = "special-competition-gallipoli"

export const WORKOUT_GROUP_GOLDEN = "GOLDEN_2024"
export const WORKOUT_GROUP_DEMANTUR = "DEMANTUR_2024"
export const WORKOUT_GROUP_HYROX = "HYROX_2024"
export const WORKOUT_GROUP_ADAPTIVE = "ADAPTIVE_2024"
export const WORKOUT_GROUP_SCP_ATHENS_2024 = "SCP_ATHENS_2024"
export const WORKOUT_GROUP_SCP_ITALIANBULLS_2024 = "SCP_ITALIANBULLS_2024"
export const WORKOUT_GROUP_SCP_ITALIANSHOWDOWN = "SCP_ITALIAN_SHOW_DOWN_2024"
export const WORKOUT_GROUP_SCP_UROBORO_2024 = "UROBORO_SUMMER_2024"
export const WORKOUT_GROUP_SCP_GALLIPOLI_2024 = "GALLIPOLI_THROWDOWN_2024"
export const WORKOUT_GROUP_CLASS = "CLASS_2024"
export const WORKOUT_GROUP_MASTER = "MASTER"
export const BOX_OWNER = "BOX_OWNERS"
export const BOX_ASSOCIATED = "BOX_ASSOCIATED"
