import { createSlice } from "@reduxjs/toolkit"
import { sortByCreationDate } from "../../helpers/SortHelper"

export const userInfo = createSlice({
    name: "userInfo",
    initialState: {
        user: undefined,
        benchmarkTypes: []
    },
    reducers: {
        load: (state, action) => {
            if (action.payload.Benchmarks.items !== undefined) {
                action.payload.Benchmarks.items.sort(sortByCreationDate)
            }
            state.user = { ...action.payload }
        },
        loadBenchmarkTypes: (state, action) => {
            state.benchmarkTypes = { ...action.payload }
        }
    }
})

// Action creators are generated for each case reducer function
export const { load, loadBenchmarkTypes } = userInfo.actions

export default userInfo.reducer
