import { benchmarks_categories_order } from "../../data/configs/benchmarks_orders"

export function sortByCreationDate(a, b) {
    if (a.createdAt < b.createdAt) {
        return 1
    }
    if (a.createdAt > b.createdAt) {
        return -1
    }
    return 0
}

export function sortByLastUpdateDate(a, b) {
    if (a.updatedAt < b.updatedAt) {
        return 1
    }
    if (a.updatedAt > b.updatedAt) {
        return -1
    }
    return 0
}

export function sortScoreTime(a, b) {
    if (a.value < b.value) {
        return -1
    }
    if (a.value > b.value) {
        return 1
    }
    return 0
}

export function sortScoreReps(a, b) {
    a.value = parseInt(a.value)
    b.value = parseInt(b.value)
    if (a.value < b.value) {
        return 1
    }
    if (a.value > b.value) {
        return -1
    }
    return 0
}

export function sortByPublishWeekNumber(a, b) {
    if (a.publishWeekNumber < b.publishWeekNumber) {
        return 1
    }
    if (a.publishWeekNumber > b.publishWeekNumber) {
        return -1
    }
    return 0
}

export function sortByPublishWeekNumberDesc(a, b) {
    if (a.publishWeekNumber > b.publishWeekNumber) {
        return 1
    }
    if (a.publishWeekNumber < b.publishWeekNumber) {
        return -1
    }
    return 0
}

export const sortBenchByCategory = (array) => {
    return [...array].sort(
        (a, b) =>
            benchmarks_categories_order.indexOf(a.category) -
            benchmarks_categories_order.indexOf(b.category)
    )
}
