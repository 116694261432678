import { createSlice } from "@reduxjs/toolkit"

export const adminData = createSlice({
    name: "adminData",
    initialState: {
        userList: null,
    },
    reducers: {
        loadUserList: (state, action) => {
            state.userList = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { loadUserList } = adminData.actions

export default adminData.reducer
