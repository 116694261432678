import {
    benchCat_ENDURANCE,
    benchCat_GYMNASTIC,
    benchCat_POWER,
    benchCat_STRENGTH,
    benchCat_WEIGHTLIFTING,
    benchCat_WORKCAPACITY
} from "../../classes/constants"

export const benchmarks_categories_order = [
    benchCat_STRENGTH,
    benchCat_WEIGHTLIFTING,
    benchCat_GYMNASTIC,
    benchCat_WORKCAPACITY,
    benchCat_POWER,
    benchCat_ENDURANCE
]
