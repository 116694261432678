import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import loadable from "@loadable/component"
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react"
import store from "./classes/redux/store"
import { Provider } from "react-redux"
import { theme } from "./data/amplify_theme"
import { Amplify } from "@aws-amplify/core"
import awsmobile from "./aws-exports"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import "./data/translation_config"
import { startsWith } from "lodash"

Sentry.init({
    dsn: "https://c014947a3b9a406d934e98d4abac8e1e@o1301184.ingest.sentry.io/6536895",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1 //non serve loggare più tutto il marasma, ora andiamo di log specifici
})
const envName =
    process.env.REACT_APP_NAME_ENV === "production"
        ? ""
        : startsWith(process.env.REACT_APP_NAME_ENV, ".")
        ? process.env.REACT_APP_NAME_ENV
        : "." + process.env.REACT_APP_NAME_ENV

// if (!process.env.REACT_APP_IS_SANDBOX) {
//     console.log = () => {};
//     console.debug = () => {};
//     console.error = () => {};
// }
const customconfig = [
    {
        name: "API_HTTP",
        endpoint: `https://api${envName}.demanturprogram.com`,
        region: "eu-central-1"
    }
]
awsmobile.aws_cloud_logic_custom = customconfig
awsmobile.Auth = { scope: ["openid", "email", "profile"] }
Amplify.configure(awsmobile)
const App = loadable(() => import("./App"))
const root = ReactDOM.createRoot(document.getElementById("root"))

if (process.env.REACT_APP_IS_SANDBOX) {
    const script = document.createElement("script")
    script.src = "https://cdn-cookieyes.com/client_data/e202915ba0442241a4195ef0/script.js"
    script.defer = true

    document.body.appendChild(script)
}
root.render(
    <div>
        <React.StrictMode>
            <Authenticator.Provider>
                <AmplifyProvider theme={theme}>
                    <Provider store={store}>
                        <App style={{ whiteSpace: "pre-line" }} />
                    </Provider>
                </AmplifyProvider>
            </Authenticator.Provider>
        </React.StrictMode>
    </div>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register()
reportWebVitals()
