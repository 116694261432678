import { createSlice } from "@reduxjs/toolkit"

export const globalApp = createSlice({
    name: "globalApp",
    initialState: {
        refresh: false,
        refreshCache: false
    },
    reducers: {
        refreshApp: (state, action) => {
            state.refresh = true
        },
        refreshAppAndCache: (state, action) => {
            state.refresh = true
            state.refreshCache = true
        },
        stopRefresh: (state, action) => {
            state.refresh = false
            state.refreshCache = false
        }
    }
})

// Action creators are generated for each case reducer function
export const { refreshApp, refreshAppAndCache, stopRefresh } = globalApp.actions

export default globalApp.reducer
