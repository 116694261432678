// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

import React from "react"
import { Diamond } from "./classes/constants"
import { toast } from "react-hot-toast"

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

export function register(config) {
    if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return
        }

        window.addEventListener("load", () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config)

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {})
            } else {
                // Is not localhost. Just register service worker
                registerValidSW(swUrl, config)
            }
        })
    }
}

function renderCustomToast() {
    console.debug("[SW] Rendering custom toast")
    toast.custom(
        (t) => (
            <div
                className={`${
                    t.visible ? "animate-enter" : "animate-leave"
                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}>
                <div className="flex-1 w-0 p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0 pt-0.5">
                            <img
                                className="h-10 w-10 rounded-full"
                                src={Diamond}
                                alt=""
                                loading="lazy"
                            />
                        </div>
                        <div className="ml-3 flex-1">
                            <p className="text-sm font-medium text-gray-900">
                                Nuova versione disponibile
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                                Clicca su aggiorna per ricaricare l'APP
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex border-l border-gray-200">
                    <button
                        onClick={() => {
                            toast.dismiss(t.id)
                            console.debug("[SW] Toast is dismissed, location is reloaded")
                            window.location.reload()
                        }}
                        className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-demantur hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                        Aggiorna
                    </button>
                </div>
            </div>
        ),
        {
            duration: 1000 * 60 * 5
        }
    )
}

function registerValidSW(swUrl, config) {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            // Check for updates at start.
            registration.update()
            // Check for updates every 5 min.
            setInterval(() => {
                registration.update()
                console.debug("[SW] Checking for update...")
            }, 1000 * 10 * 1)
            registration.onupdatefound = () => {
                const installingWorker = registration.installing
                if (installingWorker == null) {
                    return
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            // At this point, the updated precached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed.
                            // console.debug("[SW] Washing cache");
                            // caches
                            //     .keys()
                            //     .then((names) => {
                            //         for (let name of names) {
                            //             console.debug("[SW] Deleting cache", name);
                            //             caches.delete(name);
                            //         }
                            //     })
                            //     .finally(() => {
                            //         console.debug("[SW] Reloading page using history");
                            //         window.location.href = "./";
                            //     });
                            console.debug("[SW] Unregistering old SW")
                            unregister()
                            console.debug("[SW] Triggering skip waiting")
                            navigator.serviceWorker.controller.postMessage({
                                action: "skipWaiting"
                            })

                            console.debug("[SW] Rendering custom toast", navigator.serviceWorker)

                            renderCustomToast()
                            // Execute callback
                            if (config && config.onUpdate) {
                                config.onUpdate(registration)
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.

                            // Execute callback
                            console.debug("[SW] Content is cached for offline use.")
                            if (config && config.onSuccess) {
                                config.onSuccess(registration)
                            }
                        }
                    }
                }
            }
        })
        .catch((error) => {
            console.error("Error during service worker registration:", error)
        })
}

function checkValidServiceWorker(swUrl, config) {
    // Check if the service worker can be found. If it can't reload the page.
    console.debug("[SW] Check valid service worker", swUrl)
    fetch(swUrl, {
        headers: { "Service-Worker": "script" }
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get("content-type")
            if (
                response.status === 404 ||
                (contentType != null && contentType.indexOf("javascript") === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload()
                    })
                })
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config)
            }
        })
        .catch(() => {})
}

export function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                console.debug("[SW] Unregistering the old serviceworker")
                registration.unregister()
            })
            .catch((error) => {
                console.error("[SW] Error during unregistration", error.message)
            })
    }
}
